import * as React from "react";
import * as styles from "../../styles/global.module.css";

import {
  Box,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";

import { FunctionComponent } from "react";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";

const Shipping: FunctionComponent = () => {
  return (
    <>
      <Layout>
        <Box maxW="1280px" margin="0px auto" pt="120px" pb="80px" px="20px">
          <VStack pb="48px">
            <Box
              className={styles.divider}
              w="1.5rem"
              height="0.13rem"
              color="red.500"
            />
            <Box>
              <Heading as="h1" fontWeight="400" className={styles.FadeHeading}>
                Shipping, Refund and Returns Policy
              </Heading>
            </Box>
          </VStack>
          <VStack gap="1.25rem" alignItems="flex-start" spacing={5}>
            <Box>
              <Heading as="h2" size="lg">
                <b>SHIPPING POLICY</b>
              </Heading>
            </Box>
            <Stack gap={5}>
              <Heading as="h3" size="md">
                <b>Where does Doctor Medica ship?</b>
              </Heading>
              <Text>Doctor Medica ships to the US market only.</Text>
            </Stack>
            <Stack gap={5}>
              <Heading as="h3" size="md">
                <b>How much does shipping cost?</b>
              </Heading>
              <Text>
                Shipping is complimentary for orders above $500. However, if
                your order is less than $500, a shipping fee of $50 will apply.
              </Text>
            </Stack>
            <Stack gap={5}>
              <Heading as="h3" size="md">
                <b>Which shipping company does Doctor Medica use?</b>
              </Heading>
              <Text>
                It may vary depending on your local shipping services available.
                We will inform you of the tracking number and shipping method
                once we have processed your order.
              </Text>
            </Stack>
            <Stack gap={5}>
              <Heading as="h3" size="md">
                <b>When will my order arrive?</b>
              </Heading>
              <Text>
                Once an order is processed, the product will be shipped out
                within 24 hours (Monday to Friday, except for public holidays).
                The delivery approximately takes 7 to 10 business days on
                average. Guaranteed delivery time is within 14 days. In the rare
                instance that stock is not immediately available, you will
                receive notification when the order is expected to be shipped
                out. However, the transit time may vary according to region. It
                is recommended that you call our friendly customer service for a
                better idea of how long your order will take to reach you.
              </Text>
            </Stack>
            <Stack gap={5}>
              <Heading as="h2" size="lg">
                <b>RETURN POLICY</b>
              </Heading>
              <Text>
                If you face any complications with your order, please contact
                Doctor Medica immediately. Our customer service representatives
                will examine if your order qualifies for a refund. Refunds are
                at the discretion of Doctor Medica and we do not guarantee we
                will offer a refund in all circumstances.
              </Text>
            </Stack>
            <Stack gap={5}>
              <Heading as="h3" size="md">
                <b>Which orders may qualify for a refund?</b>
              </Heading>
              <Text>
                In the unlikely event you are unhappy with the order you
                received, please contact Doctor Medica. We will only issue
                refunds in the following circumstances: if we misrepresented the
                product packaging to you when you placed your order; if you
                received a damaged package; if the product is proven to be
                inactive when you initially use it. In any of these cases, you
                should hold the products until you receive further instructions
                from Doctor Medica. If we determine you should receive a refund,
                you can either request that we send a replacement or you can
                receive a full refund or account credit for that item.
              </Text>
            </Stack>
            <Stack gap={5}>
              <Heading as="h3" size="md">
                <b>What information do I need to return a product?</b>
              </Heading>
              <Text>
                Doctor Medica does not accept product returns. Do not hesitate
                to contact the customer service for guidance. If you had used
                the medical device and found it faulty in the process, keep the
                lot number and expiry date information in the client’s file.
              </Text>
            </Stack>
            <Stack gap={5}>
              <Heading as="h3" size="md">
                <b>How do I get a refund?</b>
              </Heading>
              <Text>
                Depending on the circumstances, you may be able to receive a
                refund under our instructions. You can either request we send a
                replacement to the same shipping address as the original or
                receive a full refund or account credit for that item. This
                account credit is applicable toward any future purchase of the
                same or a different product.
              </Text>
              <Text>&nbsp;</Text>
              <Text>
                Please note that any product defects/issues must be reported
                within 6 months from the date of purchase in order to receive a
                replacement or refund. We will not replace or refund any items
                with issues that are brought to our attention after this period
                ends.
              </Text>
            </Stack>
            <Stack gap={5}>
              <Heading as="h3" size="md">
                <b>Will I always qualify for a refund?</b>
              </Heading>
              <Text>
                Doctor Medica offers refunds at our own discretion. We cannot
                guarantee we will offer a refund in all circumstances. However,
                we do our best to ensure you will be happy with your medical
                supply order. If you have any questions or concerns about this
                policy, contact us for more information.
              </Text>
            </Stack>

            <Stack gap={5}>
              <Heading
                as="h2"
                size="2xl"
                fontWeight={700}
                textTransform="uppercase"
                lineHeight="133%"
              >
                <b>Doctor Medica Delivery Schedules Within the United States</b>
              </Heading>
              <Text>
                Explore the delivery schedules for Doctor Medica products within
                the United States. Stay informed about when to expect your
                orders and manage your supplies efficiently. Our dispatch time
                is 24-48 hours, which should be considered in addition to the
                delivery days.
              </Text>
            </Stack>
          </VStack>
          <TableContainer>
            <Table variant="stripedCustom" bg="#F7FAFC" border="none" mt={5}>
              <Thead py={4} bg="#F1D8C1">
                <Th
                  textTransform="capitalize"
                  py={4}
                  color="#120B0C"
                  fontSize="sm"
                  fontWeight={700}
                  borderLeftRadius="12px"
                  pl={{ base: 3, md: 5 }}
                >
                  Region
                </Th>
                <Th
                  textTransform="capitalize"
                  py={4}
                  color="#120B0C"
                  fontSize="sm"
                  fontWeight={700}
                  borderRightRadius="12px"
                >
                  Avg shipping
                </Th>
              </Thead>
              <Tbody color="#262626" fontSize="sm" fontWeight={400}>
                <Tr>
                  <Td pl={{ base: 3, md: 5 }} py={1.5}>
                    Northeast
                  </Td>
                  <Td py={1.5}>3 days</Td>
                </Tr>
                <Tr>
                  <Td pl={{ base: 3, md: 5 }} py={1.5}>
                    Midwest
                  </Td>
                  <Td py={1.5}>3 days</Td>
                </Tr>
                <Tr>
                  <Td pl={{ base: 3, md: 5 }} py={1.5}>
                    South
                  </Td>
                  <Td py={1.5}>4 days</Td>
                </Tr>
                <Tr>
                  <Td pl={{ base: 3, md: 5 }} py={1.5}>
                    West
                  </Td>
                  <Td py={1.5}>4 days</Td>
                </Tr>
                <Tr>
                  <Td pl={{ base: 3, md: 5 }} py={1.5}>
                    Territories
                  </Td>
                  <Td py={1.5}>4 days</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Layout>
    </>
  );
};

export const Head = () => (
  <SEO
    title="Doctor Medica - Shipping, Refund and Returns Policy"
    description="Doctor Medica offers a website usage policy that should be reviewed by website visitors prior to the use of the website."
  />
);

export default Shipping;
